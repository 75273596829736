import { ValidationProvider } from 'vee-validate';
import { defineComponent } from 'vue';
import { BIconCardImage, BIconCloudUpload } from 'bootstrap-vue';
// This component adds custom vee-validate validation rules to the el-upload component.
// The rules are passed in as props and are used by the ValidationProvider component.
// The ValidationProvider component is a wrapper for the el-upload component.
export default defineComponent({
    components: {
        ValidationProvider,
        BIconCardImage,
        BIconCloudUpload
    },
    props: {
        vid: {
            type: String
        },
        rules: {
            type: [Object, String, Array],
            default: ''
        },
        // must be included in props
        value: {
            type: null
        },
        httpRequest: {
            type: Function
        },
        showFileList: {
            type: Boolean,
            default: false
        },
        beforeUpload: {
            type: Function
        },
        listType: {
            type: String,
            default: 'picture'
        },
        fluidGrow: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        inputClass: {
            type: String,
            default: ''
        },
        accept: {
            type: String,
            default: ''
        },
        onRemove: {
            type: Function
        },
        label: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        innerValue: ''
    }),
    watch: {
        // Handles internal model changes.
        innerValue(newVal) {
            this.$emit('input', newVal);
        },
        // Handles external model changes.
        value(newVal) {
            this.innerValue = newVal;
        }
    },
    created() {
        if (this.value) {
            this.innerValue = this.value;
        }
    }
});
