import Vue from "vue";
import * as offerStore from "@/store/modules/OfferStore";
import GiftCard from "@/argon/components/Cards/GiftCardPreview.vue";
import BaseInput from "@/argon/components/Inputs/BaseInput.vue";
import { VIEW_STATE } from "@/store/RootState";
import { PROVIDERS_TYPE } from "@gd-mono/gd-common";
export default Vue.extend({
    components: {
        GiftCard,
        BaseInput,
    },
    props: {
        locked: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedCard: null,
            price: "$0",
            quantity: 5,
        };
    },
    computed: {
        provider() {
            return offerStore.getters.targetProvider;
        },
        shopCardsOptions() {
            const mapped = this.provider?.shop_cards?.map((val) => {
                return { text: `$${val.price}`, value: val.id };
            });
            return mapped;
        },
        viewing() {
            return offerStore.getters.viewState === VIEW_STATE.VIEWING;
        },
    },
    methods: {
        onChange() {
            offerStore.mutations.setProviderSourceMeta({
                provider_type: PROVIDERS_TYPE.GYFT,
                merchant_name: this.provider.name,
                merchant_id: this.provider.id,
                card_id: this.selectedCard,
                price: Number.parseFloat(this.price.replace("$", "")),
                quantity: this.quantity,
            });
            offerStore.mutations.setTargetOffer({
                ...offerStore.getters.targetOffer,
                quantity: this.quantity,
            });
            offerStore.mutations.setTargetOffer({
                ...offerStore.getters.targetOffer,
                metadata: {
                    ...offerStore.getters.targetOffer.metadata,
                    value_string: this.price,
                    value: this.price
                        ? Number.parseInt(this.price.replace("$", ""))
                        : 0,
                },
            });
        },
        updateQuantity() {
            offerStore.mutations.setTargetOffer({
                ...offerStore.getters.targetOffer,
                quantity: this.quantity,
            });
            this.onChange();
        },
        select(val) {
            this.selected = val;
            this.price = this.shopCardsOptions.find((val) => {
                return val.value === this.selected;
            }).text;
            this.onChange();
        },
    },
});
