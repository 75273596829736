import { render, staticRenderFns } from "./BasicModal.vue?vue&type=template&id=5c561aff&scoped=true&"
import script from "./BasicModal.vue?vue&type=script&lang=ts&"
export * from "./BasicModal.vue?vue&type=script&lang=ts&"
import style0 from "./BasicModal.vue?vue&type=style&index=0&id=5c561aff&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c561aff",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/apps/gd-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c561aff')) {
      api.createRecord('5c561aff', component.options)
    } else {
      api.reload('5c561aff', component.options)
    }
    module.hot.accept("./BasicModal.vue?vue&type=template&id=5c561aff&scoped=true&", function () {
      api.rerender('5c561aff', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modals/BasicModal.vue"
export default component.exports