import Vue from 'vue';
import * as transactionStore from '@/store/modules/TransactionStore';
import * as authStore from '@/store/modules/AuthStore';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown } from 'element-ui';
import moment from 'moment';
export default Vue.extend({
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu
    },
    async mounted() {
        await transactionStore.actions.getAllTransactions();
        // this.transactions = this.allTransactions
    },
    computed: {
        moment() {
            return moment;
        },
        allTransactions() {
            const trans = transactionStore.getters.getAllTransactions;
            const reversedTrans = trans.slice().reverse();
            return reversedTrans;
        },
        loading() {
            return transactionStore.getters.getLoading;
        },
        isAdmin() {
            return authStore.getters.isAdmin;
        },
        user() {
            return authStore.getters.getUser;
        }
    }
    // watch: {
    //     searchTerms(terms: string) {
    //         if (!terms) return (this.companies = this.allCompanies)
    //         this.companies = this.allCompanies.filter((transaction: Transaction) => {
    //             return transaction.name.toLowerCase().search(terms.toLowerCase()) !== -1
    //         })
    //     }
    // }
});
